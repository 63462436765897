<template>
<div>
  <div style="margin:20px 0 0 20px">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="check-chart">
    <Chart :tree="mapTableTree"></Chart>
  </div>

</div>
</template>

<script>
import Chart from '../dataset/components/map/chart'
export default {
  name: "checkChart.vue",
  data(){
    return {
      mapTableTree:[],
    }
  },
  components:{
    Chart
  },
  created(){
    this.getDetail();
  },
  methods:{
    getDetail(){
      let params = {
        evaluation_id:this.$route.query.evaluation_id,
        train_id:this.$route.query.train_id
      };
      this.api.dataset.datasetTrainEvaluationTrainDataMapping(params).then((res)=>{
        this.mapTableTree = res.mapTableTree;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.check-chart{
  margin: 20px;
  padding: 20px;
  background: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
}
</style>
